export interface CompayAccessRequest {
  id: number;
  status: RequestStatus;
  requestFromUserId: number;
  requestFromUserFirstName: string;
  requestFromUserLastName: string;
  requestFromUserTitle: string;
  requestFromUserLogoUrl: string;
  requestFromUserCompanyId: number;
  requestFromUserCompanyName: string;
  requestFromUserCompanyLogoUrl: string;
  requestToCompanyId: number;
  requestToCompanyName: string;
  requestToCompanyLogoUrl: string;
  createdAt: Date;
}

export enum RequestStatus {
  Pending = 'Pending',
  Approved = 'Approved',
  Declined = 'Declined',
  WaitingEmailConfirmation = 'WaitingEmailConfirmation',
}
