import { Injectable, inject } from '@angular/core';
import { type Observable } from 'rxjs';

import { HttpService } from '../core/services/http.service';

@Injectable()
export class CompanyAccessRequestsService {
  private readonly http = inject(HttpService);

  public searchCompanyAccessRequest(searchParam?: any): Observable<any> {
    return this.http.getV2(`/api/companyAccessRequests/search?`, null, searchParam);
  }

  public create(companyId: number, userEmail: string): Observable<any> {
    return this.http.postV2(`/api/companyAccessRequests`, {
      requestToCompanyId: companyId,
      requestToUserEmail: userEmail,
    });
  }

  public delete(id: number) {
    return this.http.deleteV2(`/api/companyAccessRequests/${id}`);
  }

  public approve(id: number, notes: string): Observable<any> {
    return this.http.putV2(`/api/companyAccessRequests/${id}/approve`, { notes });
  }

  public deny(id: number, notes: string): Observable<any> {
    return this.http.putV2(`/api/companyAccessRequests/${id}/decline`, { notes });
  }

  public confirmEmail(token: string): Observable<any> {
    return this.http.postV2(`/api/companyAccessRequests/confirm`, { token });
  }

  public resendConfirmation(id: number): Observable<any> {
    return this.http.postV2(`/api/companyAccessRequests/${id}/resendConfirmation`, {});
  }
}
